import React from 'react';
import { Container, Typography, Grid, Card, CardContent, Button } from '@mui/material';
import { styled } from '@mui/material/styles';

const FeatureCard = styled(Card)(({ theme }) => ({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  padding: theme.spacing(1, 0),
  justifyContent: 'space-between',
}));

const FeaturesSection: React.FC = () => (
  <Container sx={{ my: 8 }}>
    <Typography variant="h2" gutterBottom>
      Features
    </Typography>
    <Grid container spacing={4}>
      {['Code Editor', 'API Tester', 'Database Manager'].map((tool) => (
        <Grid item xs={12} md={4} key={tool}>
          <FeatureCard>
            <CardContent>
              <Typography variant="h5" component="div" gutterBottom>
                {tool}
              </Typography>
              <Typography variant="body2" color="text.secondary">
                A brief description of the {tool.toLowerCase()} and its benefits.
              </Typography>
            </CardContent>
            <CardContent>
              <Button variant="outlined" color="primary">
                Try Demo
              </Button>
            </CardContent>
          </FeatureCard>
        </Grid>
      ))}
    </Grid>
  </Container>
);

export default FeaturesSection;
