import React from 'react';
import { AppBar, Toolbar, Typography, Button, IconButton } from '@mui/material';
import { Brightness4, Brightness7 } from '@mui/icons-material';

interface HeaderProps {
  darkMode: boolean;
  onDarkModeChange: (darkMode: boolean) => void;
}

const Header: React.FC<HeaderProps> = ({ darkMode, onDarkModeChange }) => {
  const toggleTheme = () => onDarkModeChange(!darkMode);

  return (
    <AppBar position="static" color="transparent" elevation={0}>
      <Toolbar>
        <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
          MyCoderZone
        </Typography>
        <Button color="inherit">Tools</Button>
        <Button color="inherit">Features</Button>
        <Button color="inherit">Contact</Button>
        <IconButton onClick={toggleTheme} color="inherit">
          {darkMode ? <Brightness7 /> : <Brightness4 />}
        </IconButton>
      </Toolbar>
    </AppBar>
  );
};

export default Header;
