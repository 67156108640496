import React from 'react';
import { Box, Container, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

const HeroBox = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  padding: theme.spacing(10, 0),
  textAlign: 'center',
}));

const HeroSection: React.FC = () => (
  <HeroBox>
    <Container>
      <Typography sx={{ fontSize: 30, fontWeight: 600 }} gutterBottom>
        Your Go-To Spot for Essential Developer Tools
      </Typography>
    </Container>
  </HeroBox>
);

export default HeroSection;
