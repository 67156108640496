import React from 'react';
import HeroSection from './components/hero_section/HeroSection';
import FeaturesSection from './components/features_section/FeaturesSection';
import AboutSection from './components/about_section/AboutSection';
import ContactSection from './components/contact_section/ContactSection';
import Footer from './components/footer/Footer';

const LandingPage: React.FC = () => {
  return (
    <>
      <HeroSection />
      <FeaturesSection />
      <AboutSection />
      <ContactSection />
      <Footer />
    </>
  );
};

export default LandingPage;
