import React from 'react';
import { Box, Container, Typography } from '@mui/material';

const AboutSection: React.FC = () => (
  <Box sx={{ bgcolor: 'secondary.light', py: 8 }}>
    <Container>
      <Typography variant="h2" gutterBottom>
        About MyCoderZone
      </Typography>
      <Typography variant="body1" paragraph>
        MyCoderZone is your premier destination for essential developer tools. Our mission is to
        enhance your workflow and elevate productivity with a thoughtfully curated selection of
        features designed to meet your needs.
      </Typography>
    </Container>
  </Box>
);

export default AboutSection;
