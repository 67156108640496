import React, { useState } from 'react';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';
import { Helmet } from 'react-helmet';
import { lightTheme, darkTheme } from './constants/theme';
import CssBaseline from '@mui/material/CssBaseline';
import Header from './components/header/Header';
import routes from './routes';

const App: React.FC = () => {
  const [isDarkMode, setIsDarkMode] = useState(false);

  const handleIsDarkModeOnChange = (darkMode: boolean) => {
    setIsDarkMode(darkMode);
  };

  return (
    <ThemeProvider theme={isDarkMode ? darkTheme : lightTheme}>
      <CssBaseline />
      <Header darkMode={isDarkMode} onDarkModeChange={handleIsDarkModeOnChange} />
      <Helmet defaultTitle="My Coder Zone" titleTemplate="%s - My Coder Zone" />
      <Router>
        <Routes>
          {routes.map((route) => (
            <Route
              key={route.path}
              path={route.path}
              Component={() => (
                <>
                  {route.helmet && (
                    <Helmet>
                      {route.helmet.title && <title>{route.helmet.title}</title>}
                      {route.helmet.meta &&
                        route.helmet.meta.map((meta, index) => (
                          <meta key={index} name={meta.name} content={meta.content} />
                        ))}
                    </Helmet>
                  )}
                  {route.component}
                </>
              )}
            />
          ))}
        </Routes>
      </Router>
    </ThemeProvider>
  );
};

export default App;
