import { createTheme, ThemeOptions } from '@mui/material/styles';

const getDesignTokens = (mode: 'light' | 'dark'): ThemeOptions => ({
  palette: {
    mode,
    ...(mode === 'light'
      ? {
          // Light mode
          primary: {
            main: '#6c757d', // Medium Gray
            light: '#adb5bd', // Light Gray
            dark: '#343a40', // Dark Charcoal
          },
          secondary: {
            main: '#9c9c9c', // Neutral Gray
            light: '#bfbfbf', // Light Gray
            dark: '#7d7d7d', // Darker Gray
          },
          background: {
            default: '#f8f9fa', // Very Light Gray
            paper: '#ffffff', // White
          },
          text: {
            primary: '#212529', // Very Dark Gray
            secondary: '#6c757d', // Medium Gray
          },
        }
      : {
          // Dark mode
          primary: {
            main: '#adb5bd', // Light Gray
            light: '#6c757d', // Medium Gray
            dark: '#343a40', // Dark Charcoal
          },
          secondary: {
            main: '#7d7d7d', // Darker Gray
            light: '#9c9c9c', // Neutral Gray
            dark: '#4a4a4a', // Dark Gray
          },
          background: {
            default: '#212529', // Very Dark Gray
            paper: '#343a40', // Dark Charcoal
          },
          text: {
            primary: '#e9ecef', // Very Light Gray
            secondary: '#adb5bd', // Light Gray
          },
        }),
  },
  typography: {
    fontFamily: '"Inter", "Roboto", "Helvetica", "Arial", sans-serif',
    h1: {
      fontSize: '2.5rem',
      fontWeight: 700,
    },
    h2: {
      fontSize: '2rem',
      fontWeight: 600,
    },
    h6: {
      fontSize: '1.25rem',
      fontWeight: 600,
    },
    body1: {
      fontSize: '1rem',
    },
    button: {
      textTransform: 'none',
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 8,
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          borderRadius: 12,
        },
      },
    },
  },
});

export const lightTheme = createTheme(getDesignTokens('light'));
export const darkTheme = createTheme(getDesignTokens('dark'));
