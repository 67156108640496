import React from 'react';
import { Container, Typography, Grid, TextField, Button, IconButton } from '@mui/material';
import { GitHub, Twitter, LinkedIn } from '@mui/icons-material';

const ContactSection: React.FC = () => (
  <Container sx={{ my: 8 }}>
    <Typography variant="h2" gutterBottom>
      Contact Us
    </Typography>
    <Grid container spacing={4}>
      <Grid item xs={12} md={6}>
        <form>
          <TextField fullWidth margin="normal" label="Name" variant="outlined" />
          <TextField fullWidth margin="normal" label="Email" variant="outlined" />
          <TextField
            fullWidth
            margin="normal"
            label="Message"
            variant="outlined"
            multiline
            rows={4}
          />
          <Button variant="contained" color="primary" sx={{ mt: 2 }}>
            Send Message
          </Button>
        </form>
      </Grid>
      <Grid item xs={12} md={6}>
        <Typography variant="h6" gutterBottom>
          Follow Us
        </Typography>
        <IconButton aria-label="GitHub" color="inherit">
          <GitHub />
        </IconButton>
        <IconButton aria-label="Twitter" color="inherit">
          <Twitter />
        </IconButton>
        <IconButton aria-label="LinkedIn" color="inherit">
          <LinkedIn />
        </IconButton>
      </Grid>
    </Grid>
  </Container>
);

export default ContactSection;
