import React, { ReactNode } from 'react';
import LandingPage from '../features/landing_page/LandingPage';

// Define the structure for a single route
interface Route {
  path: string;
  component: ReactNode;
  exact?: boolean;
  helmet?: {
    title?: string;
    meta?: Array<{ name: string; content: string }>;
  };
  // Add any other properties you might need for your routes
}

// Define the routes array
const routes: Route[] = [
  {
    path: '/',
    component: <LandingPage />,
    exact: true,
    helmet: {
      title: 'Home',
      meta: [{ name: 'description', content: 'Welcome to our website' }],
    },
  },
];

export default routes;
